// 横スクロールの動作（GSAP）
$(function(){
  let mm = gsap.matchMedia();
  mm.add("(min-width: 1024px)", () => {
    const listWrapper = document.querySelector('.scroll-wrap');
    const list = document.querySelector('.scroll-list');
    const adjust = 600;
    gsap.to(list, {
      x: () => -(list.clientWidth + adjust - listWrapper.clientWidth),// X軸方向に何px動かすか。
      ease: 'power4.inOut',
      // ease: 'none',

      // アニメーションの対象が画面内に入ってきた時だけ処理を実行する。
      scrollTrigger: {
        trigger: '.scroll',

        // 要素の上端（top）が、ビューポートの上端（top）にきた時
        start: 'top top', 

        end: () => `+=${list.clientWidth + adjust - listWrapper.clientWidth}`,

        // アニメーションをスクロールに同期
        scrub: true,

        // ピン留め（画面に固定）
        pin: true,

        // ガタ付きを防ぐ
        anticipatePin: 1,

        // リサイズのため。
        invalidateOnRefresh: true,
      },
    });
  });
});